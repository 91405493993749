<template>
  <div class="container vendors pb-16 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <HeadlineDetailView
            :icon="icon"
            :headline="vendor.name"
            :entity="'Dienstleister'"
        ></HeadlineDetailView>
      </v-col>
      <v-col cols="12" md="6" lg="7" class="text-end">
        <a class="v-btn" @click="unArchiveVendor()" v-if="vendor.isArchived">
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-star
            </v-icon>
            Aus Archiv
          </v-btn>
        </a>
        <a class="v-btn" @click="archiveVendor()" v-else>
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-archive-outline
            </v-icon>
            Archivieren
          </v-btn>
        </a>
      </v-col>
    </v-row>
    <v-card flat class="detailCard pa-4 rounded-xl mt-6">
      <Tab
        :tabs="['Allgemein','Dateien', 'Kontakte','Adresse','Sonstiges']"
        :register="register"
        @changed="register=$event"
      >
      </Tab>
      <div class="row pa-4" v-if="register===0">
        <div class="col-12 col-sm-7">
          <KeyValueBlock
              :title="''"
              :key-values="[
              {title: 'Name', value: vendor.name,isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'vendors',
                id: vendor.id,
                row: 'name'
              }},
              {title: 'Kategorie', value: vendor.category,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'vendors',
                  id: vendor.id,
                  row: 'category',
                  type: 'autoComplete',
                   autoComplete:{
                    value: vendor.category,
                    items: vendorCategories
                  }
              }},
              {title: 'Stil', value: vendor.stil,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'vendors',
                  id: vendor.id,
                  row: 'stil',
                  type: 'multipleAutoComplete',
                   autoComplete:{
                    value: vendor.stil,
                    items: stilNames
                  }
              }},
              {title: 'Preisklasse', value: vendor.priceClass,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'vendors',
                  id: vendor.id,
                  row: 'priceClass',
                  type: 'autoComplete',
                   autoComplete:{
                    value: vendor.priceClass,
                    items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}]
                  }
              }},
              {title: 'Leistungen', value: vendor.services,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'vendors',
                  id: vendor.id,
                  row: 'services',
                  type: 'multipleAutoComplete',
                   autoComplete:{
                    value: vendor.services,
                    items: vendorServiceNames
                  }
              }},
             {
                title: 'Notizen',
                value: vendor.notes,
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'vendors',
                  id: vendor.id,
                  row: 'notes',
                  type: 'textarea',
                }
              },
              ]"
              @editEntry="editEntry"
          >
          </KeyValueBlock>
          <CustomFieldBlock :entity="'vendor'" :user="user" :entryId="vendor.id"/>
        </div>
        <div class="col-12 col-xl-4 col-sm-5 text-center">
          <Map
              v-if="vendor.lat && vendor.lng"
              :markers="[]"
              :mainMarkers="[vendor]"
              :center="latLng(vendor.lat, vendor.lng)"
              :zoom="10"
              height="400"
          >
          </Map>
          <v-img
              v-else
              :src="require('@/assets/images/drawSVGs/map.svg')"
              max-width="200px"
              class="d-inline-flex"
          />
          <br>
          <p class="text-center" v-if="!vendor.lat && !vendor.lng">
            <small>Pflege die Koordinaten oder lasse sie ermitteln, um den Dienstleister auf der Karte anzuzeigen.
            </small>
          </p>
        </div>
        <div class="col-12 col-xl-1">

        </div>
      </div>
      <v-row class="pt-6 pb-6" v-if="register===1">
        <v-col>
          <UploadFile
              :vendorId="vendor.id"
          ></UploadFile>
        </v-col>
      </v-row>
      <VirtualScrollFiles
          v-if="register===1"
          :items="files"
          :vendorId="vendor.id"
      ></VirtualScrollFiles>
      <v-row class="row pa-4" v-if="register===2">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="contactPerson of vendor.contactPersons"
                   :key="contactPerson.$id">

              <v-hover v-slot="{hover}" close-delay="170">
                <v-card
                    class="rounded-xl"
                    :class="{isMain: contactPerson.isMain}"
                    v-if="!(checkedIds.checked && checkedIds.key === contactPerson.$id)">
                  <v-card-title :class="{isMainText: contactPerson.isMain}">
                    <h5>
                      {{ contactPerson.firstname }} {{ contactPerson.lastname }}
                    </h5>
                    <v-spacer></v-spacer>
                    <v-icon v-if="hover"
                            @click="selectedEntry(
                                    {
                                            key: contactPerson.$id,
                                            cpfName: contactPerson.firstname,
                                            cplName: contactPerson.lastname,
                                            cpRole: contactPerson.role,
                                            cpEmail:contactPerson.email,
                                            cpTel: contactPerson.tel
                                       })">
                      mdi-pencil
                    </v-icon>
                  </v-card-title>
                  <v-card-text :class="{isMainText: contactPerson.isMain}">
                    <p>
                      Rolle: <b>{{ contactPerson.role }}</b>
                    </p>
                    <a :href="'tel:'+ contactPerson.tel" v-if="contactPerson.tel !== ''">
                      <v-icon color="primary" class="pe-2">mdi-phone</v-icon>
                    </a><b>{{ contactPerson.tel }}</b>
                    <br>
                    <a :href="'mailto:'+contactPerson.email" class="scale" v-if="contactPerson.email !== ''">
                      <v-icon color="primary" class="pe-2">mdi-mail</v-icon>
                    </a><b>{{ contactPerson.email }}</b>
                  </v-card-text>
                  <v-card-actions v-if="!contactPerson.isMain" :class="{isMainText: contactPerson.isMain}">
                    <v-spacer></v-spacer>
                    <v-icon @click="markAsMain(contactPerson)">mdi-star</v-icon>
                    <v-icon @click="deleteContactPerson(contactPerson)">mdi-delete</v-icon>
                  </v-card-actions>
                </v-card>
              </v-hover>
              <v-card v-if="checkedIds.checked && checkedIds.key === contactPerson.$id" class="rounded-xl">
                <v-card-text>
                  <v-text-field label="Vorname" v-model="cpfName"></v-text-field>
                  <v-text-field label="Nachname" v-model="cplName"></v-text-field>

                  <v-text-field label="Rolle" v-model="cpRole"></v-text-field>
                  <v-text-field append-icon="mdi-phone" label="Telefon" v-model="cpTel"></v-text-field>
                  <v-text-field append-icon="mdi-mail" label="Email" v-model="cpEmail"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-icon @click="editCP({cpId: contactPerson.$id})" class="me-3">mdi-check</v-icon>
                  <v-icon @click="selectedEntry(
                                    {
                                            key: '',
                                            cpfName: '',
                                            cpRole: '',
                                            cpEmail: '',
                                            cpTel: ''
                                       })">
                    mdi-cancel
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-btn class="primary text-uppercase" @click="startAddContactPerson">
              Erstellen
            </v-btn>
          </v-row>
        </v-container>
      </v-row>
      <v-row class="row pa-4" v-if="register===3">
        <v-col cols="12" md="7">
          <KeyValueBlock
              :key-values="[
                  {
                    title: 'Straße',
                    value: vendor.street,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'street'
                    }
                  },
                  {
                    title: 'Hausnummer',
                    value: vendor.streetNumber,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'streetNumber'
                    }
                  },
                  {
                    title: 'Postleitzahl',
                    value: vendor.plz,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'plz'
                    }
                  },
                  {
                    title: 'Ort',
                    value: vendor.city,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'city'
                    }
                  },
              ]"
              @editEntry="editEntry">
          </KeyValueBlock>
          <h4 class="pt-8 pb-4">Koordinaten <v-btn class="ms-3 primary" @click="getCoordinates()">Ermitteln</v-btn>
          </h4>
          <KeyValueBlock
              v-if="latLngLoaded"
              :key-values="[
                  {
                    title: 'Lat',
                    value: vendor.lat,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'lat',
                      type: 'number'
                    }
                  },
                  {
                    title: 'Lng',
                    value: vendor.lng,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'vendors',
                      id: vendor.id,
                      row: 'lng',
                      type: 'number'

                    }
                  },
                ]"
              @editEntry="editEntry">

          </KeyValueBlock>
          <Spinner v-else>

        </Spinner>
        </v-col>
        <v-col cols="12" md="5" class="text-center ">
            <Map
                v-if="vendor.lat && vendor.lng"
                :markers="[]"
                :mainMarkers="[vendor]"
                :center="latLng(vendor.lat, vendor.lng)"
                :zoom="10"
                height="400"
            >
            </Map>
              <v-img
                  v-else
                  :src="require('@/assets/images/drawSVGs/map.svg')"
                  max-width="200px"
                  class="d-inline-flex"
              />
            <br>
              <p class="text-center" v-if="!vendor.lat && !vendor.lng">
                <small>Pflege die Koordinaten oder lasse sie ermitteln, um den Dienstleister auf der Karte anzuzeigen.
                </small>
              </p>
          <div v-if="vendor.street !== '' && vendor.street !== undefined && vendor.street !== null">
            <p>
              {{ vendor.street }} {{ vendor.streetNumber }}<br>
              {{ vendor.plz }} {{ vendor.city }}
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="row pa-4" v-if="register===4">
        <v-col cols="12" md="6">
          <KeyValueBlock
              :key-values="[
                  {title: 'Erstellt am', value: getStringDate(vendor.created)},
              ]">
          </KeyValueBlock>
        </v-col>
      </v-row>
    </v-card>

    <Dialog
        :dialog="dialogAddContactPerson"
        :title="'Ansprechpartner hinzufügen'"
        :values="contactPersonCreateFields"
        :successButton="'Hinzufügen'"
        :icon="'mdi-account-plus'"
        @hideDialog="hideDialog"
        @emitNewData="addContactPerson"
    ></Dialog>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {date2String, error, msgObj, success} from "@/helper/helper";
import {latLng} from "leaflet";
const Map = () => import("@/components/generalUI/Map");
const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialog");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const VirtualScrollFiles = () => import("@/components/entities/wedding/VirtualScrollWeddingFiles");
const UploadFile = () => import("@/components/entities/wedding/UploadWeddingFile");
const Tab = () => import("@/components/generalUI/Tab");
const Spinner = () => import("@/components/generalUI/Spinner");


export default {
  name: 'vendorDetail',
  components: {
    HeadlineDetailView,
    Dialog,
    Message,
    KeyValueBlock,
    CustomFieldBlock,
    VirtualScrollFiles,
    UploadFile,
    Tab,
    Spinner,
    Map
  },
  mounted() {
    this.$store.dispatch('vendor/getVendor', {
      uid: this.user.id,
      vendorId: this.$route.params.id
    }).catch((err) => {
      this.message = error(err)
    }).then((res) => {
      this.$store.dispatch('settings/getCategories', {
        uid: this.user.id,
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('settings/getStil', {
        uid: this.user.id,
      }).catch((err) => {
        this.message = {
          show: true,
          text: err,
          color: "error",
        }
        setTimeout(() => this.message.show = false, 5000);
      })
      this.$store.dispatch('file/getFilesForVendor', {
        uid: this.user.id,
        vendorId: this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('settings/getVendorServices', {
        uid: this.user.id,
      }).catch((err) => {
        this.message = {
          show: true,
          text: err,
          color: "error",
        }
        setTimeout(() => this.message.show = false, 5000);
      })

      if (res.category.icon) {
        this.icon = res.category.icon
      }
    })

  },
  computed: {
    ...mapGetters('vendor', {
      vendor: 'vendor',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      vendorCategories: 'categoryNames',
      vendorServiceNames: 'vendorServiceNames',
      stilNames: 'stilNames'
    }),
    ...mapGetters('file', {
      files: 'files',
    })
  },
  data() {
    return {
      dialogAddContactPerson: false,
      category: '',
      checkedIds: {checked: false, key: ""},
      cpfName: "",
      cplName: "",
      cpRole: "",
      cpEmail: "",
      cpTel: "",
      register: 0,
      latLngLoaded: true,
      icon: 'mdi mdi-handshake',
      message: msgObj(),
      contactPersonCreateFields: [
        {
          title: 'Vorname',
          value: '',
          id: 0,
          type: 'string'
        },
        {
          title: 'Nachname',
          value: '',
          id: 1,
          type: 'string'
        },
        {
          title: 'E-Mail Adresse',
          value: '',
          id: 2,
          type: 'string'
        },
        {
          title: 'Telefonnummer',
          value: '',
          id: 3,
          type: 'string'
        },
        {
          title: 'Rolle /  Tätigkeit',
          value: '',
          id: 4,
          type: 'string'
        }
      ]
    }
  },
  methods: {
    latLng,
    getStringDate(date) {
      return date2String(date)
    },
    archiveVendor() {
      this.$store.dispatch('customer/editField', {
        entity: "vendors",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: true,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/dienstleister')
      })
    },
    getCoordinates() {
      if(this.vendor.street && this.vendor.plz){
        this.latLngLoaded = false
        this.$store.dispatch('locations/getCoordinates', {
          uid: this.user.id,
          address: this.vendor.street + ' ' + this.vendor.streetNumber + ' ' + this.vendor.plz + ' ' + this.vendor.city
        }).then((res) => {
          if(res.lat && res.lng){
            this.$store.dispatch('customer/editField', {
              entity: "vendors",
              uid: this.user.id,
              id: this.$route.params.id,
              newValue: res.lat,
              row: 'lat'
            }).then(()=>{
              this.$store.dispatch('customer/editField', {
                entity: "vendors",
                uid: this.user.id,
                id: this.$route.params.id,
                newValue: res.lng,
                row: 'lng'
              }).then(()=>{
                this.$store.dispatch('vendor/getVendor', {
                  uid: this.user.id,
                  vendorId: this.$route.params.id
                })
                this.latLngLoaded = true
                this.message = success('Koordinaten aktualisiert')
              })
            })
          }else{
            this.latLngLoaded = true
            this.message = error('Für diese Adresse konnten keine Koordinaten ermittelt werden.')
          }
        }).catch((err) => {
          this.latLngLoaded = true
          this.message = error(err)
        })
      }else{
        this.message = error('Bitte gib zuerst eine Adresse ein.')
      }
    },
    unArchiveVendor() {
      this.$store.dispatch('customer/editField', {
        entity: "vendors",
        uid: this.$route.params.id,
        id: this.$route.params.id,
        newValue: false,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/dienstleister')
      })
    },
    editEntry(payload) {
      this.$store.dispatch('customer/editField', {
        entity: "vendors",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('vendor/getVendor', {
          uid: this.user.id,
          vendorId: payload.id,
        })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    startAddContactPerson() {
      this.dialogAddContactPerson = true
    },
    hideDialog() {
      this.dialogAddContactPerson = false
    },
    markAsMain(contactPerson) {
      this.$store.dispatch('vendor/markAsMain', {
        contactPerson,
        vendorId: this.vendor.id,
        uid: this.user.id
      }).then(() => {
        this.message = success("Neuer Haupt-Ansprechpartner festgelegt.")
      }).catch((err) => {
        this.message = error(err)
      })
    },
    deleteContactPerson(contactPerson) {
      if (contactPerson.isMain) {
        this.message = error("Bitte zuerst anderen Hauptansprechpartner wählen.")
      } else {
        this.$store.dispatch('vendor/deleteContactPerson', {
          contactPerson,
          vendorId: this.vendor.id,
          uid: this.user.id
        }).then(() => {
          this.message = success("Ansprechpartner wurde erfoglreich gelöscht.")
        }).catch((err) => {
          this.message = error(err)
        })
      }
    },
    addContactPerson(newValues) {
      let newContactPerson = this.getContactPersonFromInput(newValues)
      newContactPerson.isMain = false
      this.$store.dispatch('vendor/createContactPerson', {
        newContactPerson,
        vendorId: this.vendor.id,
        uid: this.user.id
      }).then(() => {
            this.hideDialog()
            this.message = success("Ansprechpartner wurde erfolgreich angelegt.")
          }
      ).catch(err => {
        this.message = error(err)
      })
      this.dialogAddContactPerson = false
    },
    selectedEntry(payload) {
      if (this.checkedIds.key === "" || payload.key === "") {
        this.checkedIds = {checked: !this.checkedIds.checked, key: payload.key}

      } else {
        this.checkedIds = {checked: true, key: payload.key}
      }
      this.cpfName = payload.cpfName
      this.cplName = payload.cplName
      this.cpRole = payload.cpRole
      this.cpEmail = payload.cpEmail
      this.cpTel = payload.cpTel
    },
    editCP(payload) {
      this.$store.dispatch('vendor/editCP', {
        plannerId: this.user.id,
        cpId: payload.cpId,
        cpfName: this.cpfName,
        cplName: this.cplName,
        cpRole: this.cpRole,
        cpTel: this.cpTel,
        cpEmail: this.cpEmail,
      }).then(() => {
        this.$store.dispatch('vendor/getVendor', {
          uid: this.user.id,
          vendorId: this.vendor.id,
        })
        this.selectedEntry({
          key: '',
          cpfName: '',
          cpRole: '',
          cpEmail: '',
          cpTel: ''
        })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getContactPersonFromInput(newValues) {
      let contactPerson = {
        firstname: '',
        lastname: '',
        name: '',
        email: null,
        role: '',
        isMain: false
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Vorname':
            contactPerson.firstname = value.value
            break;
          case 'Nachname':
            contactPerson.lastname = value.value
            break;
          case 'Telefonnummer':
            contactPerson.tel = value.value
            break;
          case 'E-Mail Adresse':
            if (value.value !== '') {
              contactPerson.email = value.value
            }
            break;
          case 'Rolle /  Tätigkeit':
            contactPerson.role = value.value
            break;
          case 'Hauptansprechpartner':
            contactPerson.isMain = value.value
            break;
          case 'id':
            contactPerson.id = value.value
            break;
        }
      }
      return contactPerson
    },
  }
}
</script>
<style>
.isMain {
  height: 100%;
  background-color: rgb(243, 244, 243) !important;
}

</style>




